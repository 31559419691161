import React from 'react'
import PropTypes from 'prop-types'
import {get} from 'lodash'
import className from 'classnames'
import dynamic from 'next/dynamic'

const Custom404 = dynamic(() => import('@components/Custom404'))

const Custom404Wrapper = ({}) => {
  return <Custom404 />
}

Custom404Wrapper.propTypes = {}

export default Custom404Wrapper
